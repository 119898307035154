/* storybook-check-ignore */
export const MAPBOX_KEY =
  'pk.eyJ1Ijoib3BlbmRvb3IiLCJhIjoiY2xqaGNqY294MDJ6bTNmbzNteDZreWp6bCJ9.xN6JQcm_ppepFmpk_UUBrw';

export const ListingStates = {
  RESERVATION_PENDING: 'RESERVATION_PENDING',
  EL_SETUP: 'EL_SETUP',
  EL_LISTED: 'EL_LISTED',
  EL_IN_CONTRACT: 'EL_IN_CONTRACT',
  EL_RESERVATION_LOCKED: 'EL_RESERVATION_LOCKED',
  EL_EXPIRED: 'EL_EXPIRED',
  EL_SOLD: 'EL_SOLD',
  EL_AWAITING_SELLER_COMMITMENT: 'EL_AWAITING_SELLER_COMMITMENT',
};

export type ListingStates = (typeof ListingStates)[keyof typeof ListingStates];

export const DEFAULT_LISTING_IMAGE =
  'https://images.opendoor.com/source/s3/imgdrop-production/1c0f59135571471ca9f771104de22036.png?preset=square-2048';

export const SUPPORT_PHONE_NUMBER = '(855) 476-0049';
