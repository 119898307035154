import haversineDistance from 'haversine-distance';
import isNumber from 'lodash/isNumber';
import sortBy from 'lodash/sortBy';
import { DateTime } from 'luxon';

import {
  DEFAULT_LISTING_IMAGE,
  ListingStates,
} from '../../components/exclusives/constants/constants';
import {
  ExclusiveListingState,
  ListingType,
  VacantStatus,
} from '../../declarations/exclusives/exclusiveListingInfo';
import {
  Listing,
  ListingPhoto,
  ListingWithComputedProperties,
  ListingWithDaysRemaining,
  ListingWithListingStates,
  SimilarListing,
} from '../../declarations/exclusives/listing';
import { remainingDaysRoundedToEndTime } from './exclusiveListingInfo';

export function getListingStreet(listing: Listing) {
  return listing.address.street ?? '';
}

export function getListingAddressHumanReadable(listing: Listing) {
  return listing.address.human_readable ?? '';
}

// TODO: @exclusives move this field to be computed server side
export function getListingState(listing: Listing | SimilarListing): ListingStates {
  if (listing.exclusive_listing_info.state === ExclusiveListingState.AwaitingSellerCommitment) {
    return ListingStates.EL_AWAITING_SELLER_COMMITMENT;
  }

  if (listing.exclusive_listing_info.state === ExclusiveListingState.Setup) {
    return ListingStates.EL_SETUP;
  }

  if (listing.exclusive_listing_info.state == ExclusiveListingState.InContract) {
    return ListingStates.EL_IN_CONTRACT;
  }

  if (listing.exclusive_listing_info.state == ExclusiveListingState.Sold) {
    return ListingStates.EL_SOLD;
  }

  if (listing.exclusive_listing_info.state == ExclusiveListingState.Expired) {
    return ListingStates.EL_EXPIRED;
  }

  if (
    listing.exclusive_listing_info.reservation_end_time &&
    !listing.exclusive_listing_info.reservation_locked
  ) {
    return ListingStates.RESERVATION_PENDING;
  }

  if (
    listing.exclusive_listing_info.state == 'el_listed' &&
    listing.exclusive_listing_info.reservation_locked !== true
  ) {
    return ListingStates.EL_LISTED;
  }

  if (listing.exclusive_listing_info.reservation_locked === true) {
    return ListingStates.EL_RESERVATION_LOCKED;
  }

  // safe default to allow adding to waitlist
  return ListingStates.EL_SETUP;
}

export function getIsSellerOfferExperiment(listing: Listing) {
  return (
    !getIsOOMLS(getListingWithListingStates(listing)) &&
    Boolean(listing.exclusive_listing_info?.seller_offer_experiment)
  );
}

export function getIsSellerOwnedOpendoorPriced(listing: Listing) {
  return Boolean(listing.exclusive_listing_info?.seller_owned_with_opendoor_set_price);
}

export function getListingWithListingStates(listing: Listing): ListingWithListingStates {
  return {
    ...listing,
    // TODO: @exclusives move this field to be computed server side
    el_state: getListingState(listing),
  };
}

export function getHasOngoingOrUpcomingOpenHouses(listing: Listing) {
  return !!listing.exclusive_listing_info?.ongoing_and_upcoming_open_houses?.length;
}

export function getIsExpiringSoon(listing: Listing) {
  return listing.exclusive_listing_info.end_time
    ? DateTime.fromISO(listing.exclusive_listing_info.end_time) < DateTime.now().plus({ days: 6 })
    : false;
}

export function getIsPostOpenHouse(
  listing: ListingWithComputedProperties,
  hasOngoingOrUpcomingOpenHouses = getHasOngoingOrUpcomingOpenHouses(listing),
  expiringSoon = getIsExpiringSoon(listing),
) {
  return (
    listing.el_state === ListingStates.EL_LISTED && !hasOngoingOrUpcomingOpenHouses && expiringSoon
  );
}

export function getIsOpenHouseSchedulingComingSoon(
  listing: ListingWithComputedProperties,
  isPostOpenHouse = getIsPostOpenHouse(listing),
  hasOngoingOrUpcomingOpenHouses = getHasOngoingOrUpcomingOpenHouses(listing),
) {
  return !isPostOpenHouse && !hasOngoingOrUpcomingOpenHouses;
}

/** Gets a thumbnail image from listing or fallback to default */
export function getListingThumbnail(listing: Listing, key: keyof ListingPhoto['thumbnail_urls']) {
  if (!listing) return DEFAULT_LISTING_IMAGE;
  return (
    getListingPhotos(listing)?.[0]?.thumbnail_urls?.[key] ||
    listing.exclusive_listing_info?.local_area_map_image ||
    DEFAULT_LISTING_IMAGE
  );
}

/** Map any static image url to a listing photo object */
function mapStaticImageToListingPhoto(url: string): ListingPhoto {
  return {
    url: url,
    thumbnail_urls: {
      _375x250: url,
      _768x512: url,
      _1200x800: url,
      _1800x1200: url,
    },
  };
}

/** Gets listing photos or fallback to defaults */
export function getListingPhotos(listing: Listing): ListingPhoto[] {
  if (listing.listing_photos?.length) {
    return listing.listing_photos;
  }

  if (listing.exclusive_listing_info?.local_area_map_image) {
    return [mapStaticImageToListingPhoto(listing.exclusive_listing_info.local_area_map_image)];
  }

  return [mapStaticImageToListingPhoto(DEFAULT_LISTING_IMAGE)];
}

export function getListingWithRemainingDays<L extends Listing>(
  listing: L,
): ListingWithDaysRemaining & L {
  const daysRemaining = remainingDaysRoundedToEndTime(listing.exclusive_listing_info);
  return { ...listing, ...(isNumber(daysRemaining) && { days_remaining: daysRemaining }) };
}

export function getListingWithComputedProperties(listing: Listing): ListingWithComputedProperties {
  return getListingWithRemainingDays(getListingWithListingStates(listing));
}

export function getExclusivesEventHomeInfo(listing: Listing) {
  let homeOwner: string;
  switch (listing.exclusive_listing_info.listing_type) {
    case ListingType.SELLER_OWNED_EXCLUSIVE:
      homeOwner = '3p';
      break;
    case ListingType.OD_OWNED_EXCLUSIVE:
      homeOwner = '1p';
      break;
    case ListingType.OOMLS:
      homeOwner = 'oomls';
      break;
    default:
      homeOwner = 'unknown';
      break;
  }

  return {
    home_owner: homeOwner,
    home_status: getListingState(listing),
  };
}

export const DISCOUNT_FOR_SELLER_OWNED_BUYERS_IN_PERCENTAGE = 0.5;

export function getListingPriceCents(
  listing: Listing,
  isSellerOfferExperiment: boolean = getIsSellerOfferExperiment(listing),
) {
  if (isSellerOfferExperiment && getIsExpired(getListingWithComputedProperties(listing))) {
    // When listing.price_cents does not exist, it gets serialized to 0
    return 0;
  }
  return listing.exclusive_listing_info.exclusives_listing_price_cents || listing.price_cents;
}

export function getOriginalListingPriceCents(
  listing: Listing,
  isSellerOfferExperiment: boolean = getIsSellerOfferExperiment(listing),
) {
  if (
    listing.exclusive_listing_info.mls_listing_price_cents ===
    listing.exclusive_listing_info.exclusives_listing_price_cents
  ) {
    return null;
  }
  if (isSellerOfferExperiment && getIsExpired(getListingWithComputedProperties(listing))) {
    return null;
  }
  return listing.exclusive_listing_info.mls_listing_price_cents;
}

const TOURABLE_LISTING_STATES = [
  ListingStates.RESERVATION_PENDING,
  ListingStates.EL_LISTED,
  ListingStates.EL_IN_CONTRACT,
];

const SELF_TOURABLE_LISTING_STATES = [ListingStates.RESERVATION_PENDING, ListingStates.EL_LISTED];

export function getIsListingTourable(listing: ListingWithComputedProperties) {
  return (
    (!getIsSellerOfferExperiment(listing) || getIsSellerOwnedSelfTourable(listing)) &&
    TOURABLE_LISTING_STATES.includes(listing.el_state)
  );
}

export function getIsListingSelfTourable(listing: ListingWithComputedProperties) {
  if (getIsListing3P(listing)) {
    return getIsSellerOwnedSelfTourable(listing);
  }

  return SELF_TOURABLE_LISTING_STATES.includes(listing.el_state);
}

export function getIsSellerOwnedBlanketApproval(listing: ListingWithComputedProperties) {
  return (
    listing?.exclusive_listing_info?.seller_owned_vacant_status === VacantStatus.BLANKET_APPROVAL
  );
}
export function getIsSellerOwnedSelfTourable(listing: ListingWithComputedProperties) {
  return listing?.exclusive_listing_info?.seller_owned_vacant_status === VacantStatus.SELF_TOUR;
}
export function getIsOOMLS(listing: ListingWithComputedProperties): boolean {
  return listing.exclusive_listing_info.listing_type === ListingType.OOMLS;
}

export function getIsInContract(listing: ListingWithComputedProperties) {
  return [ListingStates.EL_IN_CONTRACT].includes(listing.el_state);
}

export function getIsAwaitingSellerCommitment(listing: ListingWithComputedProperties) {
  return listing.el_state === ListingStates.EL_AWAITING_SELLER_COMMITMENT;
}

export function getIsExpired(listing: ListingWithComputedProperties) {
  return listing.el_state === ListingStates.EL_EXPIRED;
}

export function getIsAvailableSoon(listing: ListingWithComputedProperties) {
  return listing.el_state === ListingStates.EL_SETUP;
}

export function getBallparkPriceRange(listing: Listing) {
  return listing.exclusive_listing_info.ballpark_price_range;
}

const ACTIVE_ELI_STATES: Listing['exclusive_listing_info']['state'][] = [
  ExclusiveListingState.Listed,
  ExclusiveListingState.Setup,
];

export function getActiveListings(listings: ListingWithComputedProperties[]) {
  return listings.filter((l) => ACTIVE_ELI_STATES.includes(l.exclusive_listing_info.state));
}

export function shouldShowPrelimOfferPriceRange(listing: ListingWithComputedProperties) {
  return (
    getIsSellerOfferExperiment(listing) &&
    getIsAvailableSoon(listing) &&
    listing.exclusive_listing_info.prelim_offer_price_range
  );
}

export function getIsListing3P(listing: Listing) {
  return listing.exclusive_listing_info.listing_type === ListingType.SELLER_OWNED_EXCLUSIVE;
}

export function getIsListing1P(listing: Listing) {
  return listing.exclusive_listing_info.listing_type === ListingType.OD_OWNED_EXCLUSIVE;
}

export function getIsListingOOMLS(listing: Listing) {
  return listing.exclusive_listing_info.listing_type === ListingType.OOMLS;
}

/*
 * Given an array of listings, this function will return
 * the listing that is closest to the user. If there is no
 * listing within 2,000 meters (~1.25 miles), it returns
 * null
 */
export async function getClosestListing(
  listings: ListingWithComputedProperties[],
  getCurrentPositionOptions?: PositionOptions,
) {
  const queryGeolocation = new Promise<GeolocationPosition>((success, error) =>
    navigator.geolocation.getCurrentPosition(success, error, getCurrentPositionOptions),
  );

  const { coords: userCoords } = await Promise.resolve(queryGeolocation);

  const nearbyListings = listings.filter(({ address: { latitude, longitude } }) => {
    const listingCoords = { lat: Number(latitude), lon: Number(longitude) };
    return haversineDistance(listingCoords, userCoords) <= 2_000;
  });

  const sortedNearbyListings = sortBy(nearbyListings, ({ address: { latitude, longitude } }) => {
    const listingCoords = { lat: Number(latitude), lon: Number(longitude) };
    return haversineDistance(listingCoords, userCoords);
  });

  return sortedNearbyListings.length ? sortedNearbyListings[0] : null;
}
